<template>
  <div>
    <a-row type="flex" :gutter="[24,24]">
      <a-col :span="16">
        <h5 class="font-semibold">热门交易员</h5>
        <p class="text-lg">
          精选全网来自币安排行榜、欧意牛人榜、币coin等平台的顶级交易员，收益各个平台可查。使用跟单猿，可0延迟跟单，同时享受0分润！
        </p>
      </a-col>
    </a-row>

    <!-- Project Cards -->
    <a-row type="flex" :gutter="24">
      <a-col :span="8" class="mb-24">
        <!-- Project Card -->
        <CardProject2
            :vip=false
            title="Bit浪浪"
            logo="images/head/bitlanglang.jpg"
            :team="['images/bicoin.png']"
        >
        </CardProject2>
        <!-- / Project Card -->
      </a-col>
      <a-col :span="8" class="mb-24">
        <!-- Project Card -->
        <CardProject2

            title="明明明宏"
            logo="images/head/minghong.jpg"
            :team="['images/okx.png']"
        >
        </CardProject2>
        <!-- / Project Card -->
      </a-col>
      <a-col :span="8" class="mb-24">
        <!-- Project Card -->
        <CardProject2
            title="小狐先生"
            logo="images/head/xiaohuxiansheng.jpg"
            :team="['images/binance.png']"
        >
        </CardProject2>
        <!-- / Project Card -->
      </a-col>
      <a-col :span="8" class="mb-24">
        <!-- Project Card -->
        <CardProject2
            title="风火山林_"
            logo="images/head/fenghuoshanlin.jpg"
            :team="['images/bicoin.png']"
        >
        </CardProject2>
        <!-- / Project Card -->
      </a-col>
      <a-col :span="8" class="mb-24">
        <!-- Project Card -->
        <CardProject2
            title="unusual_gainz"
            logo="images/head/unusual_gainz.jpg"
            :team="['images/binance.png']"
        >
        </CardProject2>
        <!-- / Project Card -->
      </a-col>
      <a-col :span="8" class="mb-24">
        <!-- Project Card -->
        <CardProject2
            title="星辰社区"
            logo="images/head/default-avatar.png"
            :team="['images/okx.png']"
        >
        </CardProject2>
        <!-- / Project Card -->
      </a-col>
      <a-col :span="8" class="mb-24">
        <!-- Project Card -->
        <CardProject2
            title="O荒天蛙O"
            logo="images/head/huangtianwa.png"
            :team="['images/bicoin.png']"
        >
        </CardProject2>
        <!-- / Project Card -->
      </a-col>
      <a-col :span="8" class="mb-24">
        <!-- Project Card -->
        <CardProject2
            title="吉彼特3号(私域)"
            logo="images/head/pite.png"
            :team="['images/binance.png']"
        >
        </CardProject2>
        <!-- / Project Card -->
      </a-col>
      <a-col :span="8" class="mb-24">
        <!-- Project Card -->
        <CardProject2
            title="万年"
            logo="images/head/wannian.jpg"
            :team="['images/bicoin.png']"
        >
        </CardProject2>
        <!-- / Project Card -->
      </a-col>
      <a-col :span="8" class="mb-24">
        <!-- Project Card -->
        <CardProject2
            title="Fiee的逆袭之路"
            logo="images/head/fiee.jpg"
            :team="['images/okx.png']"
        >
        </CardProject2>
        <!-- / Project Card -->
      </a-col>
      <a-col :span="8" class="mb-24">
        <!-- Project Card -->
        <CardProject2
            title="龟兔赛跑985"
            logo="images/head/guitusaipao.jpg"
            :team="['images/binance.png']"
        >
        </CardProject2>
        <!-- / Project Card -->
      </a-col>
      <a-col :span="8" class="mb-24">
        <!-- Project Card -->
        <CardProject2
            title="轮回绕过谁"
            logo="images/head/lunhui.jpg"
            :team="['images/bicoin.png']"
        >
        </CardProject2>
        <!-- / Project Card -->
      </a-col>
      <a-col :span="8" class="mb-24">
        <!-- Project Card -->
        <CardProject2
            title="卡皮巴拉(老明星)"
            logo="images/head/laomingxing.jpg"
            :team="['images/okx.png']"
        >
        </CardProject2>
        <!-- / Project Card -->
      </a-col>
      <a-col :span="8" class="mb-24">
        <!-- Project Card -->
        <CardProject2
            title="太阳V"
            logo="images/head/taiyang.jpg"
            :team="['images/bicoin.png']"
        >
        </CardProject2>
        <!-- / Project Card -->
      </a-col>
      <a-col :span="8" class="mb-24">
        <!-- Project Card -->
        <CardProject2
            title="水桥基金交易员"
            logo="images/head/qiaoshui.png"
            :team="['images/binance.png']"
        >
        </CardProject2>
        <!-- / Project Card -->
      </a-col>
      <a-col :span="8" class="mb-24">
        <!-- Project Card -->
        <CardProject2
            title="明天以后"
            logo="images/head/mingtianyihou.jpg"
            :team="['images/bicoin.png']"
        >
        </CardProject2>
        <!-- / Project Card -->
      </a-col>
      <a-col :span="8" class="mb-24">
        <!-- Project Card -->
        <CardProject2
            title="涵轩鹭"
            logo="images/head/hanxuanlu.jpg"
            :team="['images/binance.png']"
        >
        </CardProject2>
        <!-- / Project Card -->
      </a-col>
      <a-col :span="8" class="mb-24">
        <!-- Project Card -->
        <CardProject2
            title="Plymouth"
            logo="images/head/plymouth.jpg"
            :team="['images/okx.png']"
        >
        </CardProject2>
        <!-- / Project Card -->
      </a-col>
      <a-col :span="8" class="mb-24">
        <!-- Project Card -->
        <CardProject2
            title="第一目标100倍"
            logo="images/head/100bei.png"
            :team="['images/binance.png']"
        >
        </CardProject2>
        <!-- / Project Card -->
      </a-col>
      <a-col :span="8" class="mb-24">
        <!-- Project Card -->
        <CardProject2
            title="逢春化绿"
            logo="images/head/default-avatar.png"
            :team="['images/binance.png']"
        >
        </CardProject2>
        <!-- / Project Card -->
      </a-col>


      <a-col :span="8" class="mb-24">
        <a-card class="crm-bar-line header-solid h-full" :bodyStyle="{padding: 0, height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}">
          <a href="#" class="text-center text-muted font-bold">
<!--            <h3 class="font-semibold text-muted mb-0">+</h3>-->
            <h5 class="font-semibold text-muted">更多交易员接入中...</h5>
          </a>
        </a-card>

      </a-col>


    </a-row>
    <!-- / Project Cards -->

  </div>
</template>

<script>

import CardProject2 from "../components/Cards/CardProject2"


export default {
  components: {
    CardProject2,
  },
  name: "HotTrader"
}
</script>

<style scoped>

</style>