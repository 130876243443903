<template>

	<!-- Project 2 Card -->
	<a-card :bordered="false" class="card-project-2 header-solid" :bodyStyle="{paddingTop: '14px'}" :headStyle="{paddingBottom: '0' }">
		<template #title>
			<a-row type="flex" :gutter="[24,24]" align="middle">
				<a-col>
					<a-avatar
						:size="74"
						shape="square"
						style="border-radius: 8px;position: initial; background-image: linear-gradient( 310deg, #ffffff, #ffffff );"
					>
						<img :src="logo" style="width: 100%;" alt="">
					</a-avatar>
				</a-col>
				<a-col>
					<h6 class="font-semibold mb-10">{{ title }}</h6>
					<a-space :size="-12" class="avatar-chips">
						<a-avatar size="small" v-for="(src, key) in team" :key="key" :src="src" />
					</a-space>
				</a-col>
			</a-row>
		</template>
		<p><slot></slot></p>
<!--		<hr>-->
		<a-row type="flex" :gutter="[24,24]" align="middle">
      <a-button type="primary" style="margin:0 20px 14px;width: 100%" v-if="!vip || $store.state.userInfo.is_vip" @click="taskadd(title, trader_platform=4)">
        立即跟单
      </a-button>
      <a-button type="danger" style="margin:0 20px 14px;width: 100%" v-else-if="vip && !$store.state.userInfo.is_vip" @click="goToPricing">
        仅支持VIP跟单
      </a-button>
		</a-row>
	</a-card>
	<!-- / Project 2 Card -->

</template>

<script>

	export default ({
		props: {
      trader_platform:{
       type:Number,
       default:4
      },
      vip:{
       type:Boolean,
       default:true
      },
			title: {
				type: String,
				default: "",
			},
			logo: {
				type: String,
				default: "",
			},
			team: {
				type: Array,
				default: () => [],
			},
			participants: {
				type: [Number, String],
				default: 0,
			},
			due: {
				type: String,
				default: "",
			},
		},
		data() {
			return {

			}
		},
    methods:{
      goToPricing(){
        this.$router.push({path:'/pricing'})
      },
      taskadd(id, trader_platform) {
        // 导航到 Task-Add 页面
        this.$router.push({
          path: `/hot-trader/taskadd/${trader_platform}/${id}`,
        });
      },
    }
	})

</script>